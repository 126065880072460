<template>
    <ContentCardBlock v-bind="blockData" :orientation="context === 'publication' ? 'wide' : 'tall'" />
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    context: {
        type: String,
    },
});

const blockData = computed(() => {
    return {
        related: props.data?.content[0],
        overrideImage: props.data?.overrideImage,
    };
});
</script>
